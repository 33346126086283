// stylelint-disable declaration-no-important
//
// Right side table of contents
//
.ct-toc {
    @supports (position: sticky) {
        position: sticky;
        top: 4rem;
        height: calc(100vh - 4rem);
        overflow-y: auto;
    }
    order: 2;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
    font-size: .875rem;
}

.section-nav {
    padding-left: 0;
    border-left: 1px solid #eee;

    ul {
        padding-left: 1rem;

        ul {
            display: none;
        }
    }
}

.toc-entry {
    display: block;
    font-size: 1rem;

    a {
        display: block;
        padding: .125rem 1.5rem;
        color: #99979c;
        font-size: 90%;

        &:hover {
            color: $blue;
            text-decoration: none;
        }
    }
}

//
// Left side navigation
//
.ct-sidebar {
    order: 0; // background-color: #f5f2f9;
    border-bottom: 1px solid $ct-sidebar-border-color;
    background-color: $ct-sidebar-bg;

    @include media-breakpoint-up(md) {
        @supports (position: sticky) {
            position: sticky;
            top: 4rem;
            z-index: 1000;
            height: calc(100vh - 4rem);
        }
        border-right: 1px solid $ct-sidebar-border-color;
    }

    @include media-breakpoint-up(xl) {
        flex: 0 1 320px;
    }
}

.ct-links {
    padding-top: 2rem;
    padding-bottom: 1rem;
    margin-right: -15px;
    margin-left: -15px;

    @include media-breakpoint-up(md) {
        @supports (position: sticky) {
            max-height: calc(100vh - 5rem);
            overflow-y: auto;
        }
    } // Override collapse behaviors
    @include media-breakpoint-up(md) {
        display: block !important;
    }
}

.ct-search {
    position: relative; // To contain the Algolia search
    padding: 1rem 15px;
    margin-right: -15px;
    margin-left: -15px;
    border-bottom: 1px solid rgba(0, 0, 0, .05);

    .form-control:focus {
        border-color: $ct-primary-bright;
        box-shadow: 0 0 0 3px rgba($ct-primary-bright, .25);
    }
}

.ct-search-docs-toggle {
    line-height: 1;
    color: $gray-900;
}

.ct-sidenav {
    display: none;
}

.ct-toc-link {
    display: block;
    padding: .25rem 1.5rem;
    font-weight: 600;
    font-size: .875rem;
    color: #0d2b3e;

    &:hover {
        color: rgba(0, 0, 0, .85);
        text-decoration: none;
    }
}

.ct-toc-item {
    &.active {
        margin-bottom: 1rem;

        &:not(:first-child) {
            margin-top: 1rem;
        }

        >.ct-toc-link {
            color: rgba(0, 0, 0, .85);

            &:hover {
                background-color: transparent;
            }
        }

        >.ct-sidenav {
            display: block;
        }
    }
}

// All levels of nav
.ct-sidebar .nav>li>a {
    display: block;
    padding: .25rem 1.5rem;
    font-size: 84%;
    color: #4c555a;
}

.ct-sidebar .nav>li>a:hover {
    color: rgba(0, 0, 0, .85);
    text-decoration: none;
    background-color: transparent;
}

.ct-sidebar .nav>.active>a {
    font-weight: 500;
    color: #0099e5;
    background-color: transparent;
    padding-left: 2rem;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        height: 16px;
        width: 2px;
        background-color: #0099e5;
        top: 50%;
        left: 1.5rem;
        transform: translateY(-50%);
    }
}

.ct-sidebar .nav>.active:hover>a {
    color: #0099e5
}


.scrollbar-inner {
    height: 100%;

    &:not(:hover) .scroll-element {
        opacity: 0;
    }

    .scroll-element {
        transition: opacity 300ms;
        margin-right: 2px;

        .scroll-bar,
        .scroll-element_track {
            transition: background-color 300ms;
        }

        .scroll-element_track {
            background-color: transparent;
        }
    }

    .scroll-element.scroll-y {
        width: 3px;
        right: 0;
    }

    .scroll-element.scroll-x {
        height: 3px;
        bottom: 0;
    }
}
