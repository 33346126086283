//
// Close
//

.close {
    @if $enable-transitions {
        transition: $transition-base;
    }
    
    &>span:not(.sr-only) {
        background-color: $close-bg;
        color: $close-color;
        line-height: 17px;
        height: 1.25rem;
        width: 1.25rem;
        border-radius: 50%;
        font-size: 1.25rem;
        display: block;
        @if $enable-transitions {
            transition: $transition-base;
        }
    }

    &:hover,
    &:focus {
        background-color: $close-hover-bg;
        color: $close-hover-color;
        outline: none;

        span:not(.sr-only) {
            background-color: $close-hover-bg;
        }
    }
}
